<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统管理</a>
          <i>></i>
          <a href="javascript:;">考点管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">激活码管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="考点名称" class="searchboxItem ci-full">
              <span class="itemLabel">账号名称:</span>
              <el-input v-model="retrievalData.adminName" type="text" size="small" placeholder="请输入账号名称"
                        clearable/>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="createCode()">新增</el-button>
            <el-button type="primary" class="bgc-bv" round @click="$router.back()">返回</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
                      :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod"/>
              <el-table-column label="账号名称" align="left" show-overflow-tooltip prop="adminName" minWidth="100"/>
              <el-table-column label="激活码" align="left" show-overflow-tooltip prop="activationCode" minWidth="100"/>
              <el-table-column label="设备标识" align="left" show-overflow-tooltip prop="deviceCode" minWidth="120"/>
              <el-table-column label="最后访问时间" align="left" show-overflow-tooltip prop="lastLoginTime"
                               minWidth="100"/>
              <el-table-column label="是否启用" align="center">
                <div slot-scope="scope" class="table-switch">
                  <el-switch
                      :width="20"
                      v-model="scope.row.state"
                      active-value="10"
                      inactive-value="20"
                      active-color="#13ce66"
                      @change="
                      (val) => {
                        handleStu(val, scope.row.adminId);
                      }
                    "
                  ></el-switch>
                  <span>{{ scope.row.state == 10 ? "启用" : "禁用" }}</span>
                </div>
              </el-table-column>
              <el-table-column label="操作" align="center" width="180px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                             @click="copyCode(scope.row.activationCode)">复制激活码
                  </el-button>
                </template>
              </el-table-column>
              <Empty slot="empty"/>
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData"/>
      </div>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog title="新增激活码" :before-close="addCodeClose" :visible="addCodeShow" width="600px" center
               :show-close="true">
      <div>
        <el-form ref="codeForm" :model="$data" :rules="rules" class="elForm" label-position="left">
          <el-form-item label="新增激活码数量" prop="size">
            <el-input-number v-model="size" :min="1" :max="10" step="1" precision="0"/>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="width: 100%">
        <el-button @click="addCodeShow = false">取 消</el-button>
        <el-button class="bgc-bv" @click="addCode">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";

export default {
  name: "systemManage_examCenter_activationCode",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      rules: {
        size: [
          {required: true, trigger: "blur", message: "请输入生成数量"},
        ]
      },
      lastPageData: {
        compId: null,
      },
      // 检索数据
      retrievalData: {
        adminName: "", // 账号名称
      },
      size: 1,
      addCodeShow: false,
    };
  },
  created() {
    this.lastPageData.compId = this.$route.query?.compId ?? "";
    this.getData()
  },
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {
    addCode() {
      this.$post("/biz/activate/code/addCode", {
        size: this.size, compId: this.lastPageData.compId,
      }, 3000, true, 10)
          .then((res) => {
            this.addCodeShow = false;
            this.getData(-1)
            this.$message.success('操作成功')
          })
          .catch((err) => {
            return;
          });
    },
    addCodeClose() {
      this.size = 1;
      this.addCodeShow = false;
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.retrievalData,
        ...this.lastPageData,
      };
      this.doFetch({
        url: "/biz/activate/code/pageList",  //待调整
        params,
        pageNum,
      }, true, 10);
    },
    // 创建激活码
    createCode() {
      this.addCodeShow = true
    },
    //修改状态
    handleStu(state, adminId) {
      this.$post("/sys/admin/modifyStat", {
        adminId,
        state,
      }).then((res) => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.getData(-1);
        }
      });
    },
    // 复制激活码
    copyCode(value) {
      let oInput = document.createElement('input');
      oInput.value = value;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value)
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      });
      oInput.remove()
    },
  },
};
</script>
<style lang="less" scoped></style>